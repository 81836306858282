import { AVALAIBLE_LANGUAGES_DEFINITIONS } from '@core/constants/available-languages.constant';
import { InlineLoader, LangDefinition, Translation } from '@jsverse/transloco';


export function translocoInlineLoaderFactory( path: string, availableLangs: LangDefinition[] = AVALAIBLE_LANGUAGES_DEFINITIONS) : InlineLoader {
  return availableLangs.reduce((acc, lang) => {
    acc[lang.id] = () => import(`../../../${path}/${lang.id}.json`) as Promise<Translation>;
    return acc;
  }, {} as InlineLoader);
}
