import { Query } from './query.interface';

export interface QueryMap {
  [key: string]: unknown;
}

export class QueryOptions<T extends object = Record<string, string | string[] | number | boolean>> implements Query {
  private _map: Map<string, string | string[] | number | boolean>;

  constructor (json: QueryMap = {}) {
    this._map =  new Map<string, string | string[] | number | boolean>();
    if (json) {
      this.toQueryMap(json);
    }
  }

  toQueryMap (json: QueryMap): QueryOptions<T> {
    const keys = Object.keys(json);
    keys.forEach((key: string) => this._map.set(key, json[key] as string));
    return this;
  }

  toQueryString (): string {
    const obj: {[keys: string]: string | string[] | number | boolean} = Object.fromEntries(this._map);
    const entries = Object.entries(obj);
    const entriesWithValue = entries.filter((entry) => entry[1] as unknown as string !== "");
    return entriesWithValue.reduce((acc: string, curr: [string, string | string[] | number | boolean], $i: number) => {
      return curr[1] !== undefined && curr[1] !== null && curr[1] !== '' ? `${acc}${$i === 0 ? '' : '&'}${this._entry2string(curr[0], curr[1])}` : acc;
    }, '?');
  }

  toJson(): T {
    return Object.fromEntries(this._map) as unknown as T;
  }

  getParam<P = unknown>(param: string): P {
    return this._map.get(param) as P;
  }

  addParam(key: string, value: string | boolean | number): void {
    this._map.set(key, value);
  }

  private _entry2string(key: string, value: string | string[] | number | boolean): string {
    return Array.isArray(value) ? value.reduce((acc: string, curr: string | number | boolean, $i: number) => `${acc}${$i === 0 ? '' : '&'}${key}=${curr.toString()}`, '')
                                : `${key}=${value.toString()}`;
  }
}
