/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Inject, Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, take, mergeMap, finalize } from 'rxjs/operators';
import { LoginService } from './login.service';
import { WHITELIST } from './interceptors.whitelist';
import { AccessTokenIO } from './login.interfaces';
import { WINDOW } from '@core/tools/window';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  private _isRefreshing = false;
  private _refreshToken: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  public loginService = inject(LoginService);

  constructor (
    private router: Router,
    @Inject(WINDOW) private window: Window
  ) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const location = this.window.location.href;
    const except = WHITELIST.location;
    if (except.some((item: string) => location.includes(item))) {
      return next.handle(req).pipe(catchError((response: HttpErrorResponse) => this.errorManagement(response)));
    } else {
      return next.handle(req).pipe(
        catchError((response: HttpErrorResponse) => {
          if (response.status === 401) {
            if (req.url.endsWith('token/refresh/')) {
              this.loginService.onLogout();
              return throwError(() => response);
            } else {
              return this.handle401(req, next);
            }
          } else if (response.status === 404) {
            return this.handle404(response, req, next);
          } else {
            return this.errorManagement(response);
          }
        })
      );
    }
  }

  handle404(response: HttpErrorResponse, req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes('identity_providers') || req.url.includes('agent_auth_config') || req.url.includes('RetrieveClientApplicationInfo')) {
      return next.handle(req);
    } else {
      this.router.navigate(['not-found']);
      return throwError(() => response);
    }
  }

  handle401(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this._isRefreshing) {
      this._isRefreshing = true;
      this._refreshToken.next(null);
      return this.loginService.refreshToken().pipe(
        // take(1),
        mergeMap((response: AccessTokenIO) => {
          this._refreshToken.next(response.access);
          const newRequest = this.addTokenToHeader(req, response.access);
          return next.handle(newRequest);
        }),
        finalize(() => this._isRefreshing = false));
    } else {
      return this._refreshToken.pipe(
        filter((token: string | null): token is string => token !== null),
        take(1),
        mergeMap((token: string) => next.handle(this.addTokenToHeader(req, token)))
      );
    }
  }

  addTokenToHeader(req: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return req.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Authorization': `Bearer ${token}`
      }
    });
  }

  errorManagement(response: HttpErrorResponse): Observable<never> {
    return throwError(() => response);
  }
}
