import { Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Resource } from './resource';
import { QueryOptions } from './query-options.service';
import { Page, Pagination, PaginationStoreService } from './pagination.service';
import { Serializer } from './serializer';
import { map } from 'rxjs/internal/operators/map';
import { EnvironmentService } from '@core/domains/environment/environment.service';

export interface ServerResponse<T = unknown> {
  results: T[],
  count: number;
  next: string | null;
  previous: string | null;
}

export interface PaginatedResponse<T> {
  page: Pagination;
  results: T[];
}

export abstract class ResourceService<T extends Resource> {
  baseUrl: string;

  protected constructor(
    protected envService: EnvironmentService,
    protected httpClient: HttpClient,
    protected api: string,
    protected endpoint: string,
    protected serializer: Serializer<T>,
    @Optional() protected pageStore: PaginationStoreService | null
  ) {
    const domain = this.envService.environment.api_domain;
    this.baseUrl = `${domain}/api/${this.api}`;
  }

  public create(item: unknown): Observable<T> {
    return this.httpClient
      .post<T>(`${this.baseUrl}/${this.endpoint}/`, item)
      .pipe(map((response: unknown) => this.convertDatum(response)));
  }

  public update(item: T, payload?: unknown): Observable<T> {
    return this.httpClient
      .put<T>(`${this.baseUrl}/${this.endpoint}/${item.id}/`, payload ?? this.serializer.toJson(item))
      .pipe(map((response: unknown) => this.convertDatum(response)));
  }

  public patch(item: T, payload: unknown): Observable<T> {
    return this.httpClient
      .patch<T>(`${this.baseUrl}/${this.endpoint}/${item.id}/`, payload)
      .pipe(map((response: unknown) => this.convertDatum(response)));
  }

  read(id?: string | number, queryOptions?: QueryOptions<object>): Observable<T> {
    const base = id ? `${this.baseUrl}/${this.endpoint}/${id}/` : `${this.baseUrl}/${this.endpoint}/`;
    const url = queryOptions ? `${base}?${queryOptions.toQueryString()}` : base;

    return this.httpClient.get(url)
      .pipe(map((response: unknown) => this.convertDatum(response)));
  }

  list(queryOptions: QueryOptions<object> = new QueryOptions<Pagination>({ limit: 10 })): Observable<PaginatedResponse<T>> {
    const url = queryOptions ? `${this.baseUrl}/${this.endpoint}/${queryOptions.toQueryString()}` : `${this.baseUrl}/${this.endpoint}/`;

    return this.httpClient.get<ServerResponse<T>>(url).pipe(
      map((response: ServerResponse<T>) => this.buildPaginatedResponse(response, queryOptions))
    );
  }

  delete(id: string): Observable<T> {
    return this.httpClient
      .delete<T>(`${this.baseUrl}/${this.endpoint}/${id}/`);
  }

  public convertDatum(datum: unknown): T {
    return this.serializer.fromJson(datum);
  }

  public convertData(items: unknown[]): T[] {
    return items.map((item: unknown) => this.convertDatum(item));
  }

  public buildPaginatedResponse(response: ServerResponse<T>, options: QueryOptions<object>):PaginatedResponse<T> {
    const limit: number = options.getParam('limit');
    const pagination: Page = {
      count: response.count,
      next: response.next,
      previous: response.previous,
      limit
    };
    const page = new Pagination(pagination);
    return response.results.length === 0 ? { page, results: [] } : { page, results: this.convertData(response.results) };
  }
}
