import { Inject, Injectable } from '@angular/core';
import { UntypedFormGroup,
        UntypedFormControl,
        AbstractControl,
        ValidationErrors,
        ValidatorFn,
        FormGroupDirective,
        NgForm,
        UntypedFormArray,
        FormArray,
        FormGroup} from '@angular/forms';
import { DateAdapter, ErrorStateMatcher } from '@angular/material/core';
import { DateRange, MatDateRangeSelectionStrategy } from '@angular/material/datepicker';
import { Rule } from '../domains/rule/rule';
import { DateFormatter } from './date-formatter';
import { toASCII, toUnicode } from 'punycode/';

/**
 * Type guard factory for FormControl or FormArray.
 * @param reactiveForm a reactive form instance
 */
export function formControlGetterFactory(reactiveForm: UntypedFormGroup): (ctrl: string) => UntypedFormControl {
  const form = reactiveForm;
  return (ctrl: string): UntypedFormControl => {
    const control = form.get(ctrl);
    return control as UntypedFormControl;
  };
}

export function formArrayGetterFactory(reactiveForm: UntypedFormGroup): (ctrl: string) => UntypedFormArray {
  const form = reactiveForm;
  return (ctrl: string): UntypedFormArray => {
    const array = form.get(ctrl);
    return array as UntypedFormArray;
  };
}

export class CustomValidators {
  static mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const control = formGroup.get(controlName);
        const matchingControl = formGroup.get(matchingControlName);

        if (matchingControl?.errors && !matchingControl.errors['notMatch']) {
            // return if another validator has already found an error on the matchingControl
            return null;
        }

        // set error on matchingControl if validation fails
        if (control?.value !== matchingControl?.value) {
            matchingControl?.setErrors({ notMatch: true });
            return { notMatch: true };
        } else {
            matchingControl?.setErrors(null);
            return null;
        }
    };
  }

  static dateGreaterThan(ctrl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const date: Date = control.value as Date;
      const controlDate: Date = ctrl.value as Date;
      return date >= controlDate ? null : {dateNotGreaterThan: true};
    };
  }

  static dateLowerThan(ctrl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const date: Date = control.value as Date;
      const controlDate: Date = ctrl.value as Date;

      return date <= controlDate ? null : {dateNotLowerThan: true};
    };
  }

  static urlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      try {
        const url = new URL(control.value as string);
        return url ? null : {urlPattern: true};
      } catch (err) {
        return {urlPattern: true};
      }
    };
  }

  static urlOrDomainValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const value = control.value as string;

      if (!value) {
        return null;
      }

      try {
        // validate URL
        const url = new URL(value);
        return url ? null : {urlOrDomainPattern: true};
      } catch (err) {

        // Validate domain
        const re = /^[a-zA-Z0-9][a-zA-Z0-9-.]*[a-zA-Z0-9]?$/gi;
        if(re.test(value)){
          return value ? null : {urlOrDomainPattern: true};
        }

        return {urlOrDomainPattern: true};
      }
    };
  }

  static noBlankValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const value: string = control.value as string;
      if (value.trim().length === 0) {
        return {blank: true};
      }
      return null;
    };
  }

  static noEmptyArray(): ValidatorFn {
    return (ctrl: AbstractControl): ValidationErrors | null => {
      return (ctrl as FormArray).length === 0 ? {empty: true} : null;
    };
  }

  static compareStartTimeToEndTime(startDate: string, endDate: string, startTime?: string, endTime?: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const starting_date = formGroup.get(startDate) as UntypedFormControl;
      const ending_date = formGroup.get(endDate) as UntypedFormControl;
      const starting_time = startTime ? formGroup.get(startTime) as UntypedFormControl : undefined;
      const ending_time = endTime ?  formGroup.get(endTime) as UntypedFormControl : undefined;

      const startHourMinutes: string[] = starting_time ?  (starting_time.value as string).split(':') : ['00','00'];

      const complete_start_date = new Date(starting_date.value as Date);
      complete_start_date.setHours(Number(startHourMinutes[0]));
      complete_start_date.setMinutes(Number(startHourMinutes[1]));

      const endHourMinutes: string[] = ending_time ? (ending_time.value as string).split(':') : ['23','59'];

      const complete_end_date = new Date(ending_date.value as Date);
      complete_end_date.setHours(Number(endHourMinutes[0]));
      complete_end_date.setMinutes(Number(endHourMinutes[1]));

      return complete_start_date > complete_end_date ? { timeError: true } : null;
    };
  }
}

// custom validator to check that two fields match
// eslint-disable-next-line @typescript-eslint/naming-convention
export function MustMatch(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      if (matchingControl?.errors && !matchingControl.errors['notMatch']) {
          // return if another validator has already found an error on the matchingControl
          return null;
      }

      // set error on matchingControl if validation fails
      if (control?.value !== matchingControl?.value) {
          matchingControl?.setErrors({ notMatch: true });
          return { notMatch: true };
      } else {
          matchingControl?.setErrors(null);
          return null;
      }
  };
}

// Disable es-lint because of legacy code
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Matching(ctrlName: string, controlName: string): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const ctrl = group.get(ctrlName) as UntypedFormControl;
    const control = group.get(controlName) as UntypedFormControl;

    const matchWith: unknown = ctrl.value;
    const confirm: unknown = control.value;
    return matchWith === confirm ? null : {notMatch: true};
  };
}

export class CrossFieldErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && form) ? (control.dirty && form.invalid as boolean) : false;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function DateGreaterThan(ctrl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date: Date = control.value as Date;
    const controlDate: Date = ctrl.value as Date;
    return date >= controlDate ? null : {dateNotGreaterThan: true};
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function DateLowerThan(ctrl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date: Date = control.value as Date;
    const controlDate: Date = ctrl.value as Date;

    return date <= controlDate ? null : {dateNotLowerThan: true};
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function URLValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    try {
      const url = new URL(control.value as string);
      return url ? null : {urlPattern: true};
    } catch (err) {
      return {urlPattern: true};
    }
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function URLOrDomainValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value as string;

    if (!value) {
      return null;
    }

    try {
      // validate URL
      const url = new URL(value);
      return url ? null : {urlOrDomainPattern: true};
    } catch (err) {

      // Validate domain
      const re = /^[a-zA-Z0-9][a-zA-Z0-9-.]*[a-zA-Z0-9]?$/gi;
      if(re.test(value)){
        return value ? null : {urlOrDomainPattern: true};
      }

      return {urlOrDomainPattern: true};
    }
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function NoBlankValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const value: string = control.value as string;
    if (value.trim().length === 0) {
      return {blank: true};
    }
    return null;
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function NoEmptyArray(): ValidatorFn {
  return (ctrl: AbstractControl): ValidationErrors | null => {
    return (ctrl as FormArray).length === 0 ? {empty: true} : null;
  };
}


// eslint-disable-next-line @typescript-eslint/naming-convention
export function CompareStartTimeToEndTime(startDate: string, endDate: string, startTime?: string, endTime?: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const starting_date = formGroup.get(startDate) as UntypedFormControl;
    const ending_date = formGroup.get(endDate) as UntypedFormControl;
    const starting_time = startTime ? formGroup.get(startTime) as UntypedFormControl : undefined;
    const ending_time = endTime ?  formGroup.get(endTime) as UntypedFormControl : undefined;

    const startHourMinutes: string[] = starting_time ?  (starting_time.value as string).split(':') : ['00','00'];

    const complete_start_date = new Date(starting_date.value as Date);
    complete_start_date.setHours(Number(startHourMinutes[0]));
    complete_start_date.setMinutes(Number(startHourMinutes[1]));

    const endHourMinutes: string[] = ending_time ? (ending_time.value as string).split(':') : ['23','59'];

    const complete_end_date = new Date(ending_date.value as Date);
    complete_end_date.setHours(Number(endHourMinutes[0]));
    complete_end_date.setMinutes(Number(endHourMinutes[1]));

    return complete_start_date > complete_end_date ? { timeError: true } : null;
  };
}

export class PolicyValidators {

  static hasInvalidControl(array: AbstractControl): boolean {
    return (array as FormArray).controls.some((ctrl: AbstractControl) => ctrl.invalid);
  }

  static emptyArray(control: UntypedFormArray): ValidationErrors | null {
    return control.length === 0 ? {empty: true} : null;
  }

  static ruleValidator(control: AbstractControl): ValidationErrors | null {
    const rule = control.value as Rule;
    return rule.destinations.length > 0 ? null : {invalidRule: true};
  }

  static ruleRequired(array: AbstractControl): ValidationErrors | null {
    return (array as FormArray).length > 0 && !PolicyValidators.hasInvalidControl(array) ? null : {ruleRequired: true};
  }

  static activeRuleRequired(array: AbstractControl): ValidationErrors | null {
    return (array as FormArray).controls.some((ctrl: AbstractControl) => (ctrl.value as Rule).is_active === true) ? null : {hasActiveRule: true};
  }

  static uniqueDestinations(array: AbstractControl): ValidationErrors | null{

    const rules = (array as FormArray).controls.map(ctrl => (ctrl.value as Rule))

    const categoriesId = rules.map(ctrl => ctrl.categories.map(cat => cat.id)).flat();
    const categoryInThemesId = rules.map(ctrl => ctrl.themes.map(theme => theme.selected).flat().map(cat => cat.id)).flat();
    const allCategories = [...categoriesId, ...categoryInThemesId];

    const distinctCategoriesId = [...new Set(allCategories)];

    const domainListId = rules.map(ctrl => ctrl.domainLists.map(dom => dom.id)).flat();
    const distinctDomainList = [...new Set(domainListId)];

    const hasUniqueCategories = allCategories.length === distinctCategoriesId.length;
    const hasUniqueDomainList = domainListId.length === distinctDomainList.length;

    return hasUniqueCategories && hasUniqueDomainList ? null : {hasUniqueDestinations: true};
  }

  static populationRequired(form: AbstractControl): ValidationErrors | null {
    if (form instanceof FormGroup) {
      const getter = formArrayGetterFactory(form);
      return getter('directories').length > 0 ||
                  getter('groups').length > 0 ||
                  getter('users').length > 0  ? null : {populationRequired: true};
    } else {
      return null
    }
  }
}

export class DomainValidators {

  static domainValidationError = ['empty', 'invalid-ip', 'invalid-glob', 'no-tld', 'invalid-label', 'already-exists', 'too-long'];
  static whitelistValidationError = ['invalid-ip', 'invalid-glob', 'no-tld', 'invalid-label', 'already-exists', 'too-long'];

  static clean_domain(domain: string | string[] | undefined): string {
    const string = Array.isArray(domain) ? domain[0] : domain;
    let trimmed_domain = string?.toLowerCase().trim() ?? '';
    if (trimmed_domain.charAt(trimmed_domain.length - 1) === '.') {
      trimmed_domain = trimmed_domain.substring(0, trimmed_domain.length - 1);
    }
    return toUnicode(trimmed_domain);
  }

  /**
 * Verify a domain name label
 * A label must be at least one character long and at most 63 characters long.
 * A (possibly IDNA encoded) label is composed of characters encoded in the ranges [a-z] and [0-9] plus the hyphen (-) character,
 * with the additional restriction that they may not start or end with a hyphen.
 * The underscore character is also allowed as a relaxed rules as it has been used in the wild.
 *
 * @param label string
 * @return boolean
 */
  static is_valid_label(label: string): boolean {
    const valid_characters = '-abcdefghijklmnopqrstuvwxyz0123456789_'; // - is at the start so that we can check that its index is 0
    if (label.length === 0 || label.length > 63) {
      return false;
    }
    for (let i = 0; i < label.length; ++i) {
      const char = label.charAt(i);
      const index = valid_characters.indexOf(char);
      if (index === -1 || (i === 0 || i === label.length - 1) && index === 0) {
        return false;
      }
    }
    return true;
  }

  static is_ip_v4(domain: string): boolean | null {
    const match = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/.exec(domain);

    if (!match) {
      return null;
    }

    return match.find((v, i) => {
      if (i === 0) {
        // full match does not matter
        return false;
      }
      const r = parseInt(v, 10);
      return isNaN(r) || r < 0 || r > 255;
    }) === undefined;

    // TODO: Add matching against know ranges that are invalid
    // const ip_value = domain.split('.').reduce<number>((v: number, octet) => (v * 256) + parseInt(octet, 10), 0);
  }

  static is_ip_v6(domain: string): boolean | null {
    // We only look for alphanumeric string and colons with at least one colon in it.
    const match = /^[\da-f]*:[\da-f:]+$/.exec(domain);

    if (!match) {
      return null;
    }
    // This is probably an ipv6. Check further.
    const elements = domain.split(':');

    if (elements.length > 8) {
      // can only have at most 8 elements
      return false;
    }

    // We need to handle the special cases when the domain start with two colons or ends with two colons
    if (elements[0] === '') {
      // The only way a Ipv6 can start with a colon is when it start with two colons !
      if (elements[1] !== '') {
        return false;
      }
      // remove the element in excess
      elements.splice(0, 1);
    } else if (elements[elements.length - 1] === '') {
      // In the same way, the only way it ends with one colon is when it ends with two.
      if (elements[elements.length - 2] !== '') {
        return false;
      }
      elements.splice(elements.length - 1, 1);
    }

    // Search an empty string (meaning that the ipv6 contains two consecutive colons (::)
    const empty_pos = elements.indexOf('');
    if (empty_pos >= 0) {
      // Replace it with enough zeroes.
      // we are going to remove the empty element, so we need one more
      const nb_zeroes = 9 - elements.length;
      const zeroes = [];
      for (let i = 0; i < nb_zeroes; ++i) {
        zeroes.push('0');
      }
      elements.splice(empty_pos, 1, ...zeroes);
    }
    if (elements.indexOf('') !== -1) {
      // There can be only one instance of two consecutive colons
      return false;
    }
    if (elements.length !== 8) {
      // we must have exactly 8 elements now
      return false;
    }
    // We now have 8 elements. Check that each of them is a valid hexadecimal number between 0 and 65535
    const values = elements.map((element) => parseInt(element, 16));

    if (values.find((value) => isNaN(value) || value < 0 || value > 65535) !== undefined) {
      return false;
    }

    // TODO: Check here values that are known to be an issue (localhost for instance)
    // if (values.find((value, index) => value !== (index === 7 ? 1 : 0)) === undefined) {
    //   return false; // This is the loopback address
    // }

    return true;
  }

  static is_valid_glob(domain: string): boolean {
    // A glob is OK if it has only one *, at the beginning or at the end, and another character.
    const first_pos = domain.indexOf('*');
    if (first_pos !== 0 && first_pos !== domain.length - 1) {
      return false;
    }
    const second_pos = domain.indexOf('*', first_pos + 1);
    if (second_pos !== -1) {
      return false;
    }
    // We need at least one other character than the glob
    return domain.length > 1;
  }

  static validate_domain(item: string | string[] | undefined): ValidationErrors | null {
    const domain = Array.isArray(item) ? item[0] : item;

    if (domain && domain.length === 0) {
      return null;
    }

    if (domain && domain.startsWith('*') || domain && domain.endsWith('*')) {
      return DomainValidators.is_valid_glob(domain) ? null : {['invalid-glob']: true};
    }

    const isIp = domain !== undefined ? (DomainValidators.is_ip_v4(domain) ?? DomainValidators.is_ip_v6(domain)) : false;

    if (isIp === false && (domain && domain.length > 0)) { // null is acceptable (it means it's not a possible IP, i.e. no matching format)
      return {['invalid-ip']: true};
    }

    if (isIp === true) {
      return null;
    }

    const labels: string[] | undefined = domain ? toASCII(domain).split('.') : undefined;

    if (labels && labels.length > 0 && labels.length < 2) {
      return {['no-tld']: true};
    }

    if (labels && labels.find((label) => !DomainValidators.is_valid_label(label)) !== undefined) {
      return {['invalid-label']: true};
    }

    if (labels && labels.join('.').length > 253) {
      return {['too-long']: true};
    }

    return null;
  }

  /**
   * Custom validator to validate domain names or ips. Same as DomainValidators.validate_domain, but ReactiveForms compatible
   * @param control FormControl instance
   */
  static valid(control: UntypedFormControl): ValidationErrors | null {
    return DomainValidators.validate_domain(control.value as string);
  }

  /**
   * Method that returns a ValidationFn for ReactiveForms, to ensure that the domain is not already in the list
   * @param domainlist List of domains to validate against
   * @param originalValue The original domain value to not test against, when used in list edition context, i.e. the edited value
   */
  static isUnique(domainlist: string[], originalValue?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlList = originalValue ? domainlist?.filter((item: string) => item !== originalValue) : domainlist;
      return domainlist ? (controlList.includes(control.value as string) ? {'already-exists': true} : null) : null;
    };
  }
}

@Injectable()
export class MinDateRangeStrategy<D> implements MatDateRangeSelectionStrategy<D> {

  constructor(
    public dateAdapter: DateAdapter<D>,
    @Inject('MIN_RANGE') public minRange: number
  ) {}
  selectionFinished(date: D | null, currentRange: DateRange<D>, _event: Event): DateRange<D> {
    let { start, end } = currentRange;
    const today = this.dateAdapter.today();
    if (start === null || (start && end)) {
        start = date;
        end = null;
    } else if (end === null) {
        const minDate = this.dateAdapter.addCalendarDays(start, this.minRange);
        end = date && date >= minDate ? date : minDate;
        if (this.dateAdapter.compareDate(end, today) > 0) {
          end = today;
          start = this.dateAdapter.addCalendarDays(today, -this.minRange);
        }
    }

    return new DateRange<D>(start, end);
  }
  createPreview(activeDate: D | null, currentRange: DateRange<D>, _event: Event): DateRange<D> {
    if (currentRange.start && !currentRange.end) {
      const minDate = this.dateAdapter.addCalendarDays(currentRange.start, this.minRange);
      const rangeEnd = activeDate && activeDate >= minDate ? activeDate : minDate;
      return new DateRange(currentRange.start, rangeEnd);
    }

    return new DateRange<D>(currentRange.start, currentRange.end);
    }
}

@Injectable()
export class MaxDateRangeStrategy<D> implements MatDateRangeSelectionStrategy<D> {

  constructor(
    public dateAdapter: DateAdapter<D>,
    @Inject('MAX_RANGE') public maxRange: number
  ) {}

  selectionFinished(date: D | null, currentRange: DateRange<D>, _event: Event): DateRange<D> {
    let { start, end } = currentRange;
    // const today = this.dateAdapter.today();
    if (start === null || (start && end)) {
      start = date;
      end = null
    } else if (end === null) {
      const maxDate = this.dateAdapter.addCalendarDays(start, this.maxRange);
      end = date && date <= maxDate ? date : maxDate;
    }

    return new DateRange<D>(start, end);
  }

  createPreview(activeDate: D | null, currentRange: DateRange<D>, _event: Event): DateRange<D> {
    if (currentRange.start && !currentRange.end) {
      const maxDate = this.dateAdapter.addCalendarDays(currentRange.start, this.maxRange);
      const rangeEnd = activeDate && activeDate <= maxDate ? activeDate : maxDate;
      return new DateRange<D>(currentRange.start, rangeEnd);
    }
    return new DateRange<D>(currentRange.start, currentRange.end);
  }
}

export const datePickerFilterFromToday = (d: Date | null): boolean => {
  const today = new Date();
  return d ? d > today : true;
}

export const datePickerFilterBeforeToday = (d: Date | null): boolean => {
  const today = new Date();
  const max = DateFormatter.addDays(today, -31);
  return d ? d < today && d > max : true;
}

export const endDatePickerFilter = (d: Date | null, start: Date): boolean => {
  const max = DateFormatter.addDays(start, 31);
  const today = new Date();
  return d ? (d > start && d <= today && d <= max) : false;
}

export const maxEndDatePickerFilter = (maxRange: number, start: Date) => {
  return (d: Date | null): boolean => {
    const max = DateFormatter.addDays(start, maxRange);
    const today = new Date();
    return d ? (d > start && d <= today && d <= max) : false;
  }
}

export const datePickerFilterOneYearBeforeToday = (d: Date | null): boolean => {
  const today = new Date();
  const max = DateFormatter.addDays(today, -365);
  return d ? d < today && d > max : true;
}
