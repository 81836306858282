import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from '@core/resources/resource.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { AdminMe, AdminMeIO } from './admin-me';
import { AdminMeSerializer } from './adminMe.serializer';
import { EnvironmentService } from '@core/domains/environment/environment.service';
import { ADMIN_CONFIG, AdminToken } from '../administrator/administrator';

export interface AdminDetailsPayload {
  first_name: string;
  last_name: string;
}

export interface EmailPayload {
  new_email: string;
  current_password: string;
}

export interface EmailVerifyPayload {
  uid: string | null;
  token: string | null;
  new_email: string | null;
}

export interface PasswordPayload {
  current_password: string;
  new_password: string;
}

@Injectable()
export class AdminMeService extends ResourceService<AdminMe> {
  constructor(
    protected env: EnvironmentService,
    protected http: HttpClient,
    @Inject(ADMIN_CONFIG) private config: AdminToken
  ) {
    super(
      env,
      http,
      'auth',
      'admins/v2/me',
      new AdminMeSerializer(config),
      null
    );
  }

  override patch(payload: AdminDetailsPayload): Observable<AdminMe> {
    return this.httpClient
      .patch<AdminMe>(`${this.baseUrl}/${this.endpoint}/`, payload)
      .pipe(map((response: AdminMeIO) => this.serializer.fromJson(response)));
  }

  updateEmail(payload: EmailPayload): Observable<EmailPayload> {
    return this.httpClient
    .post<EmailPayload>(`${this.baseUrl}/admins/${this.config.api}/change_email/`, payload)
    .pipe(map((response) => response));
  }

  verifyEmail(payload: EmailVerifyPayload): Observable<EmailVerifyPayload> {
    return this.httpClient
      .post<EmailVerifyPayload>(`${this.baseUrl}/admins/${this.config.api}/change_email_confirm/`, payload)
      .pipe(map((response) => response));
  }

  updatePassword(payload: PasswordPayload): Observable<PasswordPayload> {
    return this.httpClient
      .post<PasswordPayload>(`${this.baseUrl}/admins/${this.config.api}/change_password/`, payload)
      .pipe(map((response) => response));
  }
}
