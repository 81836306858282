import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnDestroy, Optional, Output, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup, NgControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DateRange, MatDatepickerInputEvent, MatDateRangePicker, MatEndDate, MatStartDate, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { MatSelectChange } from '@angular/material/select';
import { MinDateRangeStrategy } from '@core/tools/custom.validator';
import { DateFormatter } from '@core/tools/date-formatter';
import { TRANSLOCO_SCOPE, TranslocoService, getBrowserLang } from '@jsverse/transloco';
import { Subject } from 'rxjs';

export interface Period {
  start_date: Date;
  end_date: Date;
}

export interface PeriodOption {
  label: string;
  value: string | number | boolean
}

export type PeriodOptions = PeriodOption[];

@Component({
  selector: 'period-select',
  templateUrl: './period-select.component.html',
  styleUrls: ['./period-select.component.scss'],
  providers: [
    { provide: 'MIN_RANGE', useValue: 1 },
    { provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: MinDateRangeStrategy },
    { provide: MatFormFieldControl, useExisting: PeriodSelectComponent },
    { provide: TRANSLOCO_SCOPE, useValue: { scope: '' } }
  ]
})
export class PeriodSelectComponent implements OnDestroy, ControlValueAccessor, MatFormFieldControl<Period> {
  static nextId = 0;
  private _value: Period;
  private _placeholder = '';
  private _required = false;
  private _disabled = false;
  private _empty = false;
  private _pickerEl: MatDateRangePicker<Date>;
  browserLang = getBrowserLang();
  rangePickerValue: Period = {
    start_date: this.value?.start_date ?? new Date(DateFormatter.addDays(new Date(), -30)),
    end_date: this.value?.end_date ?? new Date()
  };
  rangeForm: FormGroup;
  rangeType = 30;
  stateChanges: Subject<void> = new Subject();
  // id: string;
  // ngControl: NgControl | AbstractControlDirective | null;
  focused: boolean;
  touched = false;
  errorState: boolean;
  controlType = 'period-selector';
  autofilled?: boolean | undefined;
  userAriaDescribedBy?: string | undefined;

  get empty(): boolean {
    return (!this._value.start_date && !this._value.end_date) || this._empty;
  }
  set empty(isEmpty: boolean) {
    this._empty = isEmpty;
  }

  get value(): Period {
    return this._value;
  }
  set value(val: Period) {
    this._value = val;
    this.stateChanges.next();
    this.onChange(val);
    this.onTouched();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(val: boolean) {
    this._disabled = coerceBooleanProperty(val);
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input() withCustomOption = false;

  /**
   * Provides a mat-select options array for the selector
   */
  @Input() options: PeriodOptions = [
    {label: this.loco.translate('CHARTS.period-options.last_30'), value: 30},
    {label: this.loco.translate('CHARTS.period-options.last_7'), value: 7},
    {label: this.loco.translate('CHARTS.period-options.last_24'), value: 1}
  ];

  @Input() mode: 'period' | 'daterange' = 'period';

  @Input()
  set initialRange(range: number) {
    this.rangeType = range;
  }
  get initialRange(): number {
    return this.rangeType;
  }
  @Input() dateFilter: (d: Date | null) => boolean = (_) => true;


  @Output() periodSelection: EventEmitter<Period> = new EventEmitter<Period>();

  @HostBinding() id = `period-select-${PeriodSelectComponent.nextId}`;
  @HostBinding() class = 'period-select';
  @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
      return false;
    }

  @ViewChild('picker', {static: false, read: MatDateRangePicker<Date>})
    set pickerEl(el: MatDateRangePicker<Date>) {
      this._pickerEl = el;
    }
    get pickerEl(): MatDateRangePicker<Date> {
      return this._pickerEl;
    }

  constructor(
    private fm: FocusMonitor,
    private cd: ChangeDetectorRef,
    private loco: TranslocoService,
    public elRef: ElementRef<HTMLElement>,
    public errorStateMatcher: ErrorStateMatcher,
    @Self() @Optional() public ngControl: NgControl | null,
    @Inject(MAT_FORM_FIELD) @Optional() public formField?: MatFormField
  ) {
    if (this.ngControl !== null && this.ngControl !== undefined) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef);
  }

  // Linter disabled because we are forced to init those functions empty before we can populate them
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = (): void => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (val: Period) => void = (): void => {};

  select(evt: MatSelectChange): void {
    const val: number = evt.value as number;
    if (val > 0) {
      const end_date = new Date(new Date().setHours(23, 59, 59));
      const start_date: Date = DateFormatter.addDays(new Date(), -val);
      this.writeValue({start_date, end_date});
    } else {
      this.mode = 'daterange';
      this.cd.detectChanges();
      this.pickerEl.open();
    }
  }

  rangeSelect(event: MatDatepickerInputEvent<Date, DateRange<Date>>): void {
    if (event.target instanceof MatStartDate && event.value) {
      this.rangePickerValue.start_date = event.value;
    }
    if (event.target instanceof MatEndDate && event.value) {
      this.rangePickerValue.end_date = event.value;
      this.writeValue(this.rangePickerValue);
    }
  }

  writeValue(obj: Period): void {
    this.value = obj;
    this.periodSelection.emit(obj);
  }

  closeRangePicker(): void {
    this.mode = 'period';
    this.rangeType = 30;
    this.writeValue({start_date: DateFormatter.addDays(new Date(), -30), end_date: new Date()});
    this.cd.detectChanges();
  }

  registerOnChange(fn: (d: Period) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDescribedByIds(ids: string[]): void {
    const controlEl = this.elRef.nativeElement.querySelector('.period-selector-container');
    controlEl?.setAttribute('aria-describedby', ids.join(' '));
  }
  onContainerClick(): void {
    const el = (this.mode === 'daterange' ? this.elRef.nativeElement.querySelector('input') : this.elRef.nativeElement.querySelector('mat-select')) as HTMLElement;
    this.fm.focusVia(el, 'program');
  }

  onFocusIn(_event: FocusEvent): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (!this.elRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this.fm.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (!control.value) {
      this.fm.focusVia(prevElement, 'program');
    }
  }
}
