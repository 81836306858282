<ng-container>
  <div class="period-selector-container"
      [attr.aria-labelledby]="formField?.getLabelId()"
      (focusin)="onFocusIn($event)"
      (focusout)="onFocusOut($event)">

      @if (mode === 'daterange') {
        <mat-date-range-input [rangePicker]="picker" [dateFilter]="dateFilter">
          <input matStartDate [value]="rangePickerValue.start_date" (dateChange)="rangeSelect($event)" [disabled]="true">
          <input matEndDate [value]="rangePickerValue.end_date" (dateChange)="rangeSelect($event)" [disabled]="true">
        </mat-date-range-input>
        <div class="toggle-container">
          <mat-icon class="toggle" color="primary" (click)="picker.open()">today</mat-icon>
        </div>
        <mat-date-range-picker #picker [disabled]="false"></mat-date-range-picker>
      }

      @if (mode === 'daterange' && rangeType === 0) {
        <button mat-icon-button (click)="closeRangePicker()" class="close-range-picker">
          <mat-icon>close</mat-icon>
        </button>
      }

      @if (mode === 'period') {
        <mat-select [(value)]="rangeType" (selectionChange)="select($event)" color="primary">
          @for (opt of options; track $index) {
            <mat-option  [value]="opt.value">{{ opt.label }}</mat-option>
          }
          @if (withCustomOption) {
            <mat-option [value]="0">{{ browserLang === 'fr' ? 'Personnalisé' : 'Custom' }}</mat-option>
          }
        </mat-select>
      }

  </div>
</ng-container>
